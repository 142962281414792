<button [cdkMenuTriggerFor]="dropdown" [id]="controlId()" [style.height]="height()"
  class="w-full px-4 border-2 rounded-{{ rounded() }} focus:border-primary focus:outline-none {{ colorClasses() }} text-lg font-semibold"
  [disabled]="isDisabled() || loadingStatus() === loadingStatusEnum.Loading" [attr.required]="required()"
  (blur)="blur()" (click)="onClick()">
  <div class="flex flex-row flex-nowrap items-center gap-2">
    @if (loadingStatus() === loadingStatusEnum.Loading) {
    <app-spinner [color]="color()" [size]="spinnerSize()"
      [disabled]="isDisabled() || loadingStatus() === loadingStatusEnum.Loading"></app-spinner>
    } @else {
    @if (icon()) {
    <i class="{{ icon() }} {{ iconSizeClasses() }}"></i>
    }
    @if (getSelectedOption()) {
    <span class="flex-1 text-start">{{ getSelectedOption() }}</span>
    } @else {
    <span class="flex-1 text-start placeholder">{{ placeholder() }}</span>
    }
    <i class="fa-solid fa-chevron-down bg-transparent {{ triggerColorClasses() }} self-center"></i>
    }
  </div>
</button>

<ng-template #dropdown>
  <div cdkMenu
    class="bg-form-field-opaque text-form-field-color w-full border rounded-md border-form-field-border overflow-auto max-h-[576px] min-w-52">
    <div class="flex flex-col items-stretch w-full">
      @if (options()?.length === 0) {
      <span class="w-6 h-6"></span>
      }

      @if (!value) {
      <i #selectedOption></i>
      }

      @for (option of options(); track option.key) {
      <button [attr.aria-label]="option.value" cdkMenuItem (click)="onChange(option.key)"
        class="w-full p-3 pr-4 flex flex-row gap-4 hover:bg-gray-700 ">
        <span class="w-10">
          @if (option.key === value) {
          <i #selectedOption class="fa-solid fa-check text-lg"></i>
          }
        </span>
        <span class="size-full pr-7 text-left text-lg">{{ option.value }}</span>
      </button>
      }
    </div>
  </div>
</ng-template>