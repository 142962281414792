import { Injectable, inject, signal } from '@angular/core';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';

import { ApiService } from '@features/api';
import { ApiErrorResponse } from '@features/common';
import {
  GuestLoginResponse,
  LoginResponse,
  RegistrationRequest,
  UpdateDetailsRequest,
  User,
  ForgotPasswordResetRequest,
} from '../models';
import { JWTService } from './jwt.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  #apiService = inject(ApiService);
  #jwtService = inject(JWTService);

  readonly #authenticated = signal<boolean>(false);
  public authenticated = this.#authenticated.asReadonly();

  readonly #user = signal<User | null>(null);
  public user = this.#user.asReadonly();

  populate(retried: boolean = false): Observable<User> {
    return this.#apiService.get<User>('/user').pipe(
      tap((user) => this.setUser(user)),
      catchError((error) => {
        this.#jwtService.clear();
        if (retried) {
          return of(error);
        } else {
          return this.createGuest(true);
        }
      }),
    );
  }

  createGuest(retried: boolean = false): Observable<User> {
    return this.#apiService.get<GuestLoginResponse>('/user/login-guest').pipe(
      tap(({ token }) => this.#jwtService.save(token)),
      switchMap(() => this.populate(retried)),
    );
  }

  register(payload: RegistrationRequest): Observable<void> {
    return this.#apiService.post<void>('/user/register', payload);
  }

  validateAccount(token: string, unique_code: string) {
    return this.#apiService.post<void>('/user/validate-account', { token, unique_code });
  }

  login(email: string, password: string): Observable<User> {
    return this.#apiService.post<LoginResponse>('/user/login', { email, password }).pipe(
      tap(({ token }) => this.#jwtService.save(token)),
      switchMap(() => this.populate()),
    );
  }

  logout(): Observable<User> {
    return this.#apiService.get<void>('/user/logout').pipe(switchMap(() => this.createGuest()));
  }

  requestPasswordReset(email: string) {
    return this.#apiService.post<void>('/user/forgot-password', { email });
  }

  resetPassword(payload: ForgotPasswordResetRequest) {
    return this.#apiService.post<ApiErrorResponse>('/user/forgot-password-reset', payload);
  }

  updateDetails(payload: UpdateDetailsRequest): Observable<User> {
    return this.#apiService.post<User>('/user', payload).pipe(tap((user) => this.setUser(user)));
  }

  updatePassword(current_password: string, new_password: string, new_password_confirmation: string) {
    return this.#apiService.post<ApiErrorResponse>('/user/update-password', {
      current_password,
      new_password,
      new_password_confirmation,
    });
  }

  updateEmail(email: string): Observable<User> {
    return this.#apiService.post<User>('/user', { email }).pipe(tap((user) => this.setUser(user)));
  }

  private setUser(user: User) {
    this.#user.set(user);
    this.#authenticated.set(!user.is_guest && user.account_active);
  }

  private clear(): void {
    this.#jwtService.clear();
    this.#user.set(null);
    this.#authenticated.set(false);
  }
}
