import { CommonModule } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-password-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-password-field.component.html',
  styleUrl: './form-password-field.component.scss',
})
export class FormPasswordFieldComponent {
  control = input.required<FormControl>();
  name = input.required<string>();
  placeholder = input<string | null>(null);
  autocomplete = input<'off' | 'new_password' | 'password'>('off');

  protected revealed = signal<boolean>(false);

  protected toggle() {
    this.revealed.update((value) => !value);
  }
}
